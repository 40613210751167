<template>
    <div>
        <header-portrate style="display:none" />

    <div class="m-2 mxmnheight" style="border:1px solid #000;background:lightgoldenrodyellow">
        <posHeader /> 
        <v-row class="m-2 mt-6">
            <v-col cols="12" style="display:flex;justify-content:center;">
                <div class="p-2 text-center" style="border:1px solid #000;width:200px;background:black !important;color:#fff !important">{{ lang.employees }}</div>
            </v-col>
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;">
                <v-row class="mt-2">
                  <v-col cols="12" style="text-align:left">
                    <b-button style="display:none;font-size:1.1rem;border:1px solid #000;width:200px;background:black !important;color:#fff !important" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/employees'})">{{lang.employees}}</b-button> 
                    

                    <b-button variant="success"  
                    class="btn-sm  " 
                    :style="`width:145px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="resetTitle()" 
                    v-b-modal.addEmployee 
                    id="multiply-button"
                    color="success">
                    <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_employee}}</div>
                    </b-button>
                  </v-col>                  
                </v-row>
                <v-row class="mt-2">
                  <v-data-table
                    :headers="headers"
                    :items="emprows"
                    :items-per-page="10"
                    class="elevation-1"
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:100px" class="text-center">{{ row.item.mobile }}</td>
                            <td class="text-center" nowrap>{{ row.item.employee_name }}</td>
                            <td class="text-center">{{ row.item.id_number }}</td>
                            <td class="text-center">{{ row.item.salary }}</td>
                            <td class="text-center">{{ row.item.home_allown }}</td>
                            <td class="text-center">{{ row.item.tarns_allown }}</td>
                            <td class="text-center" nowrap>{{ row.item.food_allown }}</td> 
                            <td class="text-center" nowrap>{{ row.item.others_allown }}</td>
                            <!-- <td class="text-center">{{ row.item.gozi }}</td> -->
                            <td class="text-center">{{ row.item.percentage }}</td>
                            <td class="text-center">{{ row.item.ftotal }}</td>
                            <!-- <td style="padding-top:5px !important;padding-bottom:5px !important;" class="text-center">
                                <v-btn style="width:100px;font-size:0.2em;background:#000;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="ViewItem(row.item)" v-b-toggle.add_employee>{{lang.update}}</v-btn>
                                <v-btn style="width:100px;font-size:0.2em;background:green;color:#FFF;height:25px !important;" @click="viewEmp(row.item)" v-b-toggle.view_employee>{{lang.view}}</v-btn>
                            </td> -->
                            <!-- <td style="margin-left: 5px;width:80px;background:red;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                <div class="updateBTN" v-b-toggle.add_employee @click="ViewItem(row.item)">
                                {{lang.salary_paid}}</div>
                            </td> -->
                            <td style="color:#fff !important;margin-left: 5px;width:80px;background:green;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                <div class="updateBTN" style="background:green !important" v-b-modal.salary_paid @click="payForIt(row.item)">
                                    {{ lang.salary_paid }}</div>
                            </td>
                            <td style="margin-left: 5px;width:80px;background:darkcyan;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                <div class="updateBTN" style="background:darkcyan !important;color:#fff !important" v-b-modal.empl_ids @click="statForIt(row.item)">
                                    {{ lang.client_statment }}</div>
                            </td>
                            <td style="color:#fff !important;text-align:center;margin-left: 5px;width:80px;background:red;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                <div class="updateBTN" v-b-modal.addEmployee @click="ViewItem(row.item)">
                                {{lang.update}}</div>
                            </td>
                            <td style="margin-left: 5px;width:80px;background:blue;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                <div class="viewBTN" v-b-modal.view_employee @click="viewEmp(row.item)">
                                {{lang.view}}</div>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-container style="direction:rtl">
                        <div class="text-center pt-2" style="direction:rtl">
                        <v-pagination
                            v-model="page"
                            value="10"
                            :length="pageCount"
                            style="direction:rtl"
                        ></v-pagination>
                        </div>
                    </v-container>
                </v-row>
            </v-col>
        </v-row>
        <posAddEmployeeVue ref="addeditemp" />
        <posEmployeeView ref="viewemployee" />
        <employeesStatement ref="employeesStatement" />
        <salaryPaid ref="salaryPaid" />
        <vue-snotify></vue-snotify>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import posAddEmployeeVue from '@/components/pos-addEmployee.vue'
import posEmployeeView from '@/components/pos-employeeView.vue'
import employeesStatement from '@/views/pos-employeesStatement.vue'
import salaryPaid from '@/components/pos-salaryPaid.vue';
import posHeader from '@/components/pos-header.vue'
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { HeaderPortrate,posAddEmployeeVue,posEmployeeView,employeesStatement,salaryPaid,posHeader },
    name: 'POS',
    data() {
        return {
            page: 1,
            pageCount: 15,
            emprows: [],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            search: '',
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
        sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      
      headers: function () {
        return [
          {
              text: this.lang.mobile,
              align: 'center',
              sortable: true,
              value: 'mobile',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.emp_name,
              align: 'center',
              sortable: true,
              value: 'employee_name',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.id_number,
              align: 'center',
              sortable: true,
              value: 'id_number',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.basic_salary,
              align: 'center',
              sortable: true,
              value: 'salary',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.home_allownance,
              align: 'center',
              sortable: true,
              value: 'home_allown',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.trans_allownance,
              align: 'center',
              sortable: true,
              value: 'tarns_allown',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.food_allown,
              align: 'center',
              sortable: true,
              value: 'food_allown',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.other_allown,
              align: 'center',
              sortable: true,
              value: 'others_allown',
              class: 'pa-0 backBlack'
          },
          // {
          //     text: this.lang.goze_perc,
          //     align: 'center',
          //     sortable: true,
          //     value: 'gozi',
          //     class: 'pa-0 backBlack'
          // },
          
          {
              text: this.lang.percentate + ' %',
              align: 'center',
              sortable: true,
              value: 'percentage',
              class: 'pa-0 backBlack'
          },
          // {
          //     text: 'الخصم',
          //     align: 'center',
          //     sortable: true,
          //     value: 'deduct',
          // },
          {
              text: this.lang.total,
              align: 'center',
              sortable: true,
              value: 'ftotal',
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.salary_paid,
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.client_statment,
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.update,
              class: 'pa-0 backBlack'
          },
          {
              text: this.lang.view,
              class: 'pa-0 backBlack'
          }
        ];
      }
    },
    methods: {
      payForIt(item){
          this.$refs.salaryPaid.empid = item.id
          this.$refs.salaryPaid.getEmployees()
      },
      statForIt(item){
          this.$refs.employeesStatement.accountn = item
          this.$refs.employeesStatement.getReport();
      },
      getSum(){
          let stable = this.emprows;
          for(var i in stable){
              let total = 0;
              total = +total + +stable[i].salary;
              total = +total + +stable[i].home_allown;
              total = +total + +stable[i].tarns_allown;
              total = +total + +stable[i].food_allown;
              total = +total + +stable[i].others_allown;
              this.emprows[i].ftotal = this.$RoundNum(total);
          }
      },
      resetTitle()
      {
          this.$refs.addeditemp.user.startdate = this.$refs.addeditemp.date1;
          this.$refs.addeditemp.toptitle = 'اضافة موظف';
          this.$refs.addeditemp.resetValues();
      },
      ViewItem(item)
      {
          this.$refs.addeditemp.user = item;
          this.$refs.addeditemp.toptitle = 'تعديل موظف';
      },
      viewEmp(item){
          this.$refs.viewemployee.empid = item.id;
          this.$refs.viewemployee.employee = item;
          this.$refs.viewemployee.employees = item;
          this.$refs.viewemployee.getEmployees();
      },
      getEmployees() {
            const post = new FormData();
            post.append("type" , "getEmployees")
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase))
            post.append("data[month]",this.month);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.emprows = res.results.data.results;
            }).then(()=>{
                this.getSum();
            })
        }
    },
    created() {
       this.getEmployees();
    },
}

</script>

<style>
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
.addButtom{
  position:absolute;
  text-align:center;
  left:0px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
</style>