<template>
    <div>
        <b-modal
            id="empl_ids"
            ref="modal"
            :title="$parent.lang.statment"
            size="xl"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.statment }}
                    </div>
                </div>
            </template>
                
                <v-row :style="`direction:`+$parent.lang.dir+`;margin:5px;`">
                <v-col
                cols="12"
                md="12"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{$parent.lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{$parent.lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                            <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getReport()">{{$parent.lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                </v-col>
            </v-row>
            <v-row :style="`direction:`+$parent.lang.dir+`;margin:5px;`">
                <v-col cols="12" md="12" sm="12" :style="`direction:`+$parent.lang.dir">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center backBlack">{{$parent.lang.account_statement}} - {{account_name}}</th>
                            </tr>
                            <tr>
                                <th class="text-center backBlack">{{$parent.lang.date}}</th>
                                <th class="text-center backBlack">{{$parent.lang.description}}</th>
                                <th class="text-center backBlack">له</th>
                                <th class="text-center backBlack">عليه</th>
                                <!-- <th class="text-center">{{$parent.lang.balance}}</th> -->
                            </tr>
                            <tr>
                                <!-- <th colspan="4"> {{ $parent.lang.old_balance }}: {{ $RoundNums($convertToComa(old_balance,$store.state.licenseType.moneyComma),2) }}</th> -->
                                <th colspan="4" class="text-end"> {{ $parent.lang.old_balance }}: {{ $RoundNums(old_balance,2) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in accountrows" :key="index">
                                <td class="text-center" style="direction:ltr">{{item.date}}</td>
                                <td class="text-start" style="direction:rtl">{{item[`description_`+$parent.lang.langname]}}</td>
                                <td class="text-center" style="direction:ltr">{{$RoundNums(item.debit,2)}}</td>
                                <td class="text-center" style="direction:ltr">{{$RoundNums(item.credit,2)}}</td>
                                <!-- <td class="text-center" style="direction:ltr">{{item.date}}</td>
                                <td class="text-center" style="direction:ltr">{{item[`description_`+$parent.lang.langname]}}</td>
                                <td class="text-center" style="direction:ltr">{{$RoundNums($convertToComa(parseFloat(item.debit),$store.state.licenseType.moneyComma),2)}}</td>
                                <td class="text-center" style="direction:ltr">{{$RoundNums($convertToComa(parseFloat(item.credit),$store.state.licenseType.moneyComma),2)}}</td> -->
                                <!-- <td class="text-center" style="direction:ltr">0</td> -->
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-end" colspan="2">{{ $parent.lang.total }}</th>
                                <th class="text-center backGreen">{{ $RoundNums(allTotl.dtot,2) }}</th>
                                <th class="text-center backRed">{{ $RoundNums(allTotl.ctot,2) }}</th>
                            </tr>
                            <tr>
                                <!-- <th colspan="4"> {{$parent.lang.balance}} : {{$RoundNums($convertToComa(balance,$store.state.licenseType.moneyComma),2)}} </th> -->
                                <th colspan="2" class="backBlack text-end" style="font-size:1.3rem;"> {{$parent.lang.balance}} </th>
                                <th class="backBlack text-center" style="font-size:1.3rem;"> {{$RoundNums(balance,2) >= 0 ? $RoundNums(balance,2) : 0}} </th>
                                <th class="backBlack text-center" style="font-size:1.3rem;"> {{0 >= $RoundNums(balance,2) ? parseFloat($RoundNums(balance,2)) * -1 : 0}} </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>

            <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='printMe()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.print }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            accountn: {
                full_name: '',
            },
            accountrows: [],
            balance: 0,
            old_balance: 0,
            sd: {
                day: 1,
                month:  1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
        account_name: function() {
            if(this.$parent.lang.langname=='ar')
            {
                return this.accountn.employee_name
            }else{
                return this.accountn.employee_name;
            }
        },
        btotals: function()
        {
            let t = 0;
            let tc = 0;
            let td = 0;
            for(let i=0;i<this.accountrows.length;i++){
                tc = parseFloat(tc) + parseFloat(this.accountrows[i].credit)
                td = parseFloat(td) + parseFloat(this.accountrows[i].depit)
            }
            t = parseFloat(td) - parseFloat(tc);
            return this.$RoundNum(t);
        },
        allTotl: function(){
            let t = {
                dtot: 0,
                ctot: 0,
            }
            for(let i=0;i<this.accountrows.length;i++){
                t.dtot = parseFloat(t.dtot) + parseFloat(this.accountrows[i].debit);
                t.ctot = parseFloat(t.ctot) + parseFloat(this.accountrows[i].credit);
            }
            return t;
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.$parent.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.$parent.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.$parent.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
    },
    methods: {
        printMe(){
            const post = new FormData();
            post.append('type',"printEmpStat");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[empid]',this.accountn.id);
     
            for(let i=0;i<this.accountrows.length;i++){
                Object.keys(this.accountrows[i]).forEach(key => {
                    post.append("data[data]["+i+"]["+key+"]",this.accountrows[i][key])
                })
            }
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                window.open("../api/print.php?empstatme="+res.results.data+'&empid='+this.accountn.id,"_blank")
            })
        },
        getReport(){
            const post = new FormData();
            post.append('type',"getEmpStateReport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[empid]',this.accountn.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                    this.balance = res.results.balance;
                    this.old_balance = res.results.old_balance;
                }
            })
        }
    },
}
</script>