<template>
    <div>
        <b-modal
            v-show="!show"
            id="addEmployee"
            ref="modal"
            :title="$parent.lang.add_employee"
            size="xl"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ toptitle }}
                    </div>
                </div>
            </template>
            <v-form ref="eform" v-model="valid">
            <div class="px-3 py-2" style="direction:rtl;">
                <v-row>
                    <!-- <v-col cols="12" md="4" sm="6">
                        <v-combobox
                            item-text="text"
                            item-value="value"
                            v-model="user.company"
                            :items="companies"
                            chips
                            label="الشركة"
                        ></v-combobox>
                    </v-col> -->
                    <v-col cols="12" md="4" sm="6">
                        <v-dialog
                            ref="dialog1"
                            v-model="modal"
                            :return-value.sync="date1"
                            persistent
                            width="290px"
                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <label>{{lang.workstartdate}}</label>
                            <b-form-input class="inborder"
                                v-model="user.startdate"
                                :label="lang.workstartdate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></b-form-input>
                            </template>
                            <v-date-picker
                            v-model="user.startdate"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal=false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog1.save(user.startdate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.full_name}} *</label>
                        <b-form-input class="inborder keyboardInput"
                            :label="lang.full_name + ` *`"
                            v-model="user.employee_name"
                            @click="openKeyBoard()"
                            >{{ user.employee_name }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.id_number}} *</label>
                        <b-form-input class="inborder"
                            :label="lang.id_number + ` *`"
                            v-model="user.id_number"
                            :rules="nameRules"
                            
                            >{{ user.id_number }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.goze_perc}}</label>
                        <b-form-input class="inborder"
                            :label="lang.goze_perc"
                            v-model="user.gozi"
                            :rules="nameRules"
                            
                            >{{ user.gozi }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.mobile}}</label>
                        <b-form-input class="inborder"
                            :label="lang.mobile + ` *`"
                            v-model="user.mobile"
                            :rules="nameRules"
                            
                            >{{ user.mobile }}</b-form-input>
                    </v-col>
                    <!-- <v-col cols="12" md="4" sm="6">
                        <b-form-input class="inborder"
                            label="البريد الالكتروني"
                            v-model="user.email"
                            >{{ user.email }}</b-form-input>
                    </v-col> -->
                    <v-col cols="12" md="4" sm="6">
                        <v-combobox
                            item-text="text"
                            item-value="value"
                            v-model="user.nationality"
                            :items="nationalities"
                            chips
                            :label="lang.nationality"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.status}}</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="user.status"
                            :options="statuses"
                            :label="lang.status"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-30px;margin-top:5px;"><i class="fas fa-arrow-down"></i></b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.basic_salary}} *</label>
                        <b-form-input class="inborder"
                            :label="lang.basic_salary + ` *`"
                            v-model="user.salary"
                            :rules="numberRules"
                            >{{ user.salary }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.home_allownance}}</label>
                        <b-form-input class="inborder"
                            :label="lang.home_allownance +` *`"
                            v-model="user.home_allown"
                            >{{ user.home_allown }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.trans_allownance}}</label>
                        <b-form-input class="inborder"
                            :label="lang.trans_allownance +` *`"
                            v-model="user.tarns_allown"
                            >{{ user.tarns_allown }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.food_allown}}</label>
                        <b-form-input class="inborder"
                            :label="lang.food_allown +` *`"
                            v-model="user.food_allown"
                            >{{ user.food_allown }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.other_allown}}</label>
                        <b-form-input class="inborder"
                            :label="lang.other_allown +` *`"
                            v-model="user.others_allown"
                            >{{ user.others_allown }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.percentage}}</label>
                        <b-form-input class="inborder"
                            :label="lang.percent +` *`"
                            v-model="user.percentage"
                            >{{ user.percentage }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <label>{{lang.work_hours}}</label>
                        <b-form-input class="inborder"
                            v-model="user.work_hours"
                            />
                    </v-col>
                </v-row>
            </div>
        </v-form>
            <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addEmployee()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ SUBMIT_NAME }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    props: ['newTitle','upEmployee'],
    data() {
        return {
            show: true,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal:false,
            toptitle:'اضافة موظف',
            search: '',
            valid: false,
            nameRules:[
                v => !!v || 'هذا الحقل مطلوب'
            ],
            numberRules:[
                v => !!v || 'هذا الحقل مطلوب',
                v => v > 0 || 'يجب ان يكون الحقل عدد اكبر من الصفر'
            ],
            // perms:[
            //     {
            //         text: 'موظف كاشير',
            //         value: 3,
            //     },
            //     {
            //         text: 'محاسب',
            //         value: 2,
            //     },
            //     {
            //         text: 'ادارة عامة',
            //         value: 1,
            //     },
            // ],
            // permissions:{
            //     text: 'موظف كاشير',
            //     value: 3,
            // },
            // statuses:[
            //     {text: 'على رأس العمل' , value: 1},
            //     {text: 'اجازة سنوية' , value: 2},
            //     {text: 'مفصول من العمل' , value: 3},
            //     {text: 'عقد منتهي' , value: 4},
            //     {text: 'مستقيل' , value: 5},
            // ],
            // companies:[
            //     {text: 'Next Miles' , value: 1},
            //     {text: 'SAMCSOFT' , value: 2},
            //     {text: 'SAMCOTEC' , value: 3},
            // ],
            users:[],
            userid:0,
            nationalities: [],
            
            SUBMIT_NAME: 'حفظ',
            user:{
                id: 0,
                employee_name: '', 
                id_number: '', 
                nationality: '', 
                status: 1, 
                salary: '', 
                home_allown: '', 
                tarns_allown: '', 
                food_allown: '', 
                others_allown: '', 
                percentage: '',
                email: '',
                mobile: '',
                company: '',
                gozi: '',
                startdate: '',
                work_hours: 12,
                finger_print_id: '',
            },
            staticOpt: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        formtitle: function(){
            return this.lang.add_employee
        },
        statuses: function(){
            let t= [];
            for(let i=0;i<this.staticOpt.length;i++){
                if(this.staticOpt[i].option_phrase == 'empStatus'){
                    t.push({
                        text: this.staticOpt[i][`name_`+this.lang.langname],
                        value: this.staticOpt[i].value
                    })
                }
            }
            return t;
        }
    },
    methods: {
        openKeyBoard(){
            // CommandRun.run("C:\\WINDOWS\\system32\\osk.exe", []);
        },
        changeUser() {
            this.user = this.upEmployee;
        },
        getUsers(){
            const post = new FormData();
            post.append("type","getAgents");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[emp]",1);
            post.append("data[id]",0);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                // // console.log(response.data);
                this.users = response.data.results.data.agents;
            })
        },
        getStaticOption(){
            const post = new FormData();
            post.append("type","getStaticOptions");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",0);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                // // console.log(response.data.results.data);
                this.staticOpt = response.data.results.data;
            })
        },
        resetValues(){
            this.userid = 0;
            this.user = {
                id: 0,
                employee_name: '', 
                id_number: '', 
                nationality: '', 
                status: 1, 
                salary: '', 
                home_allown: '', 
                tarns_allown: '', 
                food_allown: '', 
                others_allown: '', 
                percentage: '',
                mobile: '',
                email: '',
                gozi: 10,
                startdate: this.date1,
                work_hours: 12,
                finger_print_id: ''
            }
        },
        addEmployee(){
            // // console.log(this.user);
            let vald = true;
            let message = '';
            if(this.user.employee_name == ''){
                vald = false;
                message = 'اسم الموظف مطلوب';
            }
            else if(this.user.mobile == ''){
                vald = false;
                message = 'رقم الاتصال مطلوب';
            }
            else if(this.user.salary == ''){
                vald = false;
                message = 'الراتب الاساسي مطلوب';
            }
            if(vald === false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
            this.user.home_allown = (this.user.home_allown == 0 || this.user.home_allown == '') ? 0 : this.user.home_allown; 
            this.user.tarns_allown = (this.user.tarns_allown == 0 || this.user.tarns_allown == '') ? 0 : this.user.tarns_allown;
            this.user.food_allown = (this.user.food_allown == 0 || this.user.food_allown == '') ? 0 : this.user.food_allown; 
            this.user.others_allown = (this.user.others_allown == 0 || this.user.others_allown == '') ? 0 : this.user.others_allown; 
            this.user.percentage = (this.user.percentage == 0 || this.user.percentage == '') ? 0 : this.user.percentage;
            this.user.gozi = (this.user.gozi == 0 || this.user.gozi == '') ? 10 : this.user.gozi;


            const post = new FormData();
            post.append("type",'addEmployee');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            
            post.append('data[id]',this.user.id);
            post.append('data[employee_name]',this.user.employee_name);
            post.append('data[status]',this.user.status);
            post.append('data[nationality]',this.user.nationality);
            post.append('data[home_allown]',this.user.home_allown);
            post.append('data[tarns_allown]',this.user.tarns_allown);
            post.append('data[food_allown]',this.user.food_allown);
            post.append('data[others_allown]',this.user.others_allown);
            post.append('data[percentage]',this.user.percentage);
            post.append('data[mobile]',this.user.mobile);
            post.append('data[email]',this.user.email);
            post.append('data[id_number]',this.user.id_number);
            post.append('data[salary]',this.user.salary);
            post.append('data[gozi]',this.user.gozi);
            post.append('data[startdate]',this.user.startdate);

            // id: 0,
            //     employee_name: '', 
            //     id_number: '', 
            //     nationality: '', 
            //     status: 1, 
            //     salary: 0, 
            //     home_allown: '', 
            //     tarns_allown: 0, 
            //     food_allown: '', 
            //     others_allown: 0, 
            //     percentage: 0,
            //     mobile: '',
            //     email: '',

            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    // // console.log("results",res);
                    if(res.error.number == 200){
                        if (this.$snotify.notifications.length > 0) {
                            this.$snotify.notifications.forEach(notification => {
                                this.$snotify.remove(notification.id)
                            });
                        }
                        message = "تمت اضافة / تعديل الموظف بنجاح";
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        
                        this.resetValues();
                        document.getElementById('add_employee').style.display = 'none';
                        this.$parent.getEmployees();
                    }else{
                        message = 'يرجى مراجعة البيانات المدخلة';
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                }
            ).then(() => {
                this.$parent.editemployee = 0;
                document.getElementsByClassName("closethis")[0].click()
            })
        },
        getNationalities(){
            this.nationalities = [];
            const post = new FormData();
            post.append("type" , "getNationalities")
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase))
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                //this.nationalities = res.results.data;
                for(var item in res.results.data){
                    this.nationalities.push(res.results.data[item].title)
                }
            })
        },
        getEmployees(){

            this.nationalities = [];
            const post = new FormData();
            post.append("type" , "getEmployees")
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase))
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.nationalities = res.results.data;
            })
        },
        getInfo(){
            
            if(this.eid != null && this.eid != 0){
                this.user.employee_name = this.eitem.myname; 
                this.user.id_number = this.eitem.myid; 
                this.user.nationality = this.eitem.nationality; 
                this.user.mobile = this.eitem.mobile; 
            }
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
       this.getNationalities();
       this.getStaticOption()
    //    //this.getUsers();
    //    setInterval(() => {
    //        this.formtitle = this.changeTitle
    //    },1000);
    //    this.user = this.$parent.curEmp;
    //    // console.log("emp" , this.upEmployee);
    },
}
</script>
<style>
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
</style>